import { render, staticRenderFns } from "./VrCategory.vue?vue&type=template&id=086d3b88&scoped=true&"
import script from "./VrCategory.vue?vue&type=script&lang=js&"
export * from "./VrCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086d3b88",
  null
  
)

component.options.__file = "VrCategory.vue"
export default component.exports